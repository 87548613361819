<template>
  <v-row>
    <v-col
      cols="12"
      xl="3"
      lg="4"
      md="4"
      sm="6"
      xs="12"
      v-for="(item, index) in 6"
      :key="index"
    >
      <v-sheet
        color="grey lighten-4"
        class="pa-0"
      >
        <v-skeleton-loader
          class="mx-auto"
          height="150px"
          type="article"
        ></v-skeleton-loader>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CatalogSkeletonLayout",
};
</script>